import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { FC, useRef } from 'react';
import { formContainer } from './form.css.ts';
import { Input } from '@synoptic/ui-kit/input/input.tsx';
import { useToast } from '@synoptic/ui-kit/toast/toast-provider.tsx';
import { joinToWaitlist } from '../api.ts';
import { useNavigate } from '@remix-run/react';
import { paths } from '@/routes-utils/paths.ts';
import { clsx } from 'clsx';
import { landingInput } from '../page.css.ts';

type FormInputs = {
  email: string;
};

export const WaitlistForm: FC<{
  className?: string;
}> = ({ className }) => {
  const toast = useToast();
  const { handleSubmit, formState, reset, register } = useForm<FormInputs>();
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const submit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await joinToWaitlist({ email: data.email });

      navigate(paths.makeEarlyAccess(data.email));
      reset();
    } catch (e) {
      toast.error({ title: String(e) });
    }
  };

  return (
    <form
      ref={formRef}
      className={clsx(formContainer, className)}
      onSubmit={handleSubmit(submit)}
    >
      <Input
        className={landingInput}
        data-cy={`waitlist-email-input`}
        type={'email'}
        placeholder={'Enter your email'}
        {...register('email', {
          required: true,
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Invalid email',
          },
        })}
        errorMessage={formState.errors.email?.message}
      />

      <Button
        data-cy={'join-waitlist-button'}
        size={'large'}
        disabled={formState.isSubmitting}
        type="submit"
        loading={formState.isSubmitting}
      >
        Join the waitlist
      </Button>
    </form>
  );
};
